import React from "react"
import dynamic from "next/dynamic"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"
import { Event } from "common/types/graphql"
import { EventsView } from "./Events.view"

export interface EventsProps {
  events?: Event[]
}

const EventsEdit = dynamic<EventsProps>(() => import("./Events.edit").then((mod) => mod.EventsEdit))

export const Events = ({ events }: EventsProps) => {
  const { editing } = usePageProps<EditPageProps>()
  const EventsComponent = editing ? EventsEdit : EventsView
  return <EventsComponent events={events} />
}
