import { EventsProps } from "./Events"
import { ContentSummary, ContentSummaryVariant } from "common/components/ContentSummary"
import { imageSizes } from "common/lib/imageSizes"
import { ImageAspectRatio } from "modules/editor/types"

/**
 * Component for displaying events
 */
export const EventsView = ({ events = [] }: EventsProps) => {
  return (
    <section className="mb-4">
      <div className="grid grid-cols-3 gap-4 sm:gap-6 md:grid-cols-12">
        {events.map(({ name, trackingUrl, byline, imageSrc }, index) => (
          <div
            key={index}
            className="col-span-full flex justify-between gap-3 border-t border-gray-200 pt-4 md:col-span-6"
          >
            <ContentSummary
              title={name}
              url={trackingUrl}
              byline={byline}
              image={{ src: imageSrc ? imageSrc : undefined }}
              variant={ContentSummaryVariant.BaseWide}
              imageSizes={imageSizes({ lg: 0.125, sm: 0.25 })}
              imageFit="contain"
              imageAspectRatio={ImageAspectRatio.Square}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
